import React, { useState, useEffect, createRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { addProject } from '../../actions/projects';

const NuevoEvento = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Eventos',
            path: '/eventos'
        },
        {
            activo: true,
            nombre: 'Nuevo evento',
            path: '/eventos/nuevo'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [eventoData, setEventoData] = useState({
        nombre: null, 
        inicio: null,
        fin: null,
        tiene_limite: false,
        limite: null,
        soluciones: false,
        descripcion: null,
        imagen: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
    });

    const [imagePicture, setImagePicture] = useState({
        img: undefined
    });

    let img = createRef();

    /* Nuevo evento */
    const handleChangeImg = () => {
        setImagePicture({
            ...imagePicture, 
            img: img.current.files[0]
        });
    }

    const handleInputChange = ({ target }) => {
        setEventoData({
            ...eventoData,
            [target.name]: target.value
        });
    }
    
    const handleDatePicker = (name, value) => {
        setEventoData({
            ...eventoData,
            [name]: value
        });
    }

    const handleCheckbox = ({ target }, name) => {
        if(name == 'tiene_limite'){
            setEventoData({
                ...eventoData,
                tiene_limite: target.checked, 
                limite: null
            });
        }else{
            setEventoData({
                ...eventoData,
                soluciones: target.checked
            });
        }
    }

    const handleGuardarEvento = async () => {
        if(eventoData.nombre && eventoData.descripcion){
            let formData = new FormData();

            formData.append('nombre', eventoData.nombre);
            formData.append('inicio', eventoData.inicio);
            formData.append('fin', eventoData.fin);
            formData.append('limite', eventoData.limite);
            formData.append('soluciones', eventoData.soluciones);
            formData.append('descripcion', eventoData.descripcion);
            formData.append('portada', imagePicture.img);

            await dispatch(addProject(formData));

            await navigate("/eventos");
        }else{
            await dispatch(setAlert('Nombre y descripción son obligatorios', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(editBreadcrumb(rutasBread));
    }, [rutasBread]);    

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 5%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #111F62;
                        }
                        
                        .img-wrap{
                            position: relative;
                            //width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 5%;
                        }

                        .img-upload:before{
                            content: "";
                            font-family: "Font Awesome 6 Free" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #21B4E9;
                            width: 100%;
                            height: 200px;
                            border-radius: 5%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }

                        .selectIconos, .select-group {
                            height: 100%;
                        }

                        .delete {
                            padding: 0.39rem 0.70rem !important;
                            border-radius: 0 25% 25% 0 !important;
                            height: 100%;
                        }

                        #label_limite_check:before {
                            content: "";
                            display: inline-block;
                            height: 100%;
                            vertical-align: middle;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Nuevo evento</h3>
                    
                    <div className="row mb-5">
                        <div className="col-12 form-group text-center">
                            <label htmlFor="photo-upload" className="custom-file-upload fas">
                                <div className="img-wrap img-upload">
                                    <img id="img-photo" htmlFor="photo-upload" src={imagePicture.img != null ? URL.createObjectURL(imagePicture.img) : eventoData.imagen}/>
                                </div>
                                <input id="photo-upload" type="file" accept="image/*" ref={img} onChange={handleChangeImg}/> 
                            </label>
                        </div>
                    </div>
                
                    <div className="row">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={eventoData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <DatePicker
                                        id="inicio"
                                        name="inicio"
                                        className="form-control text-center input-datepícker"
                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        showTimeInput
                                        minDate={new Date()}
                                        selected={ eventoData.inicio != null ? new Date(eventoData.inicio) : '' }
                                        onChange={ date => handleDatePicker('inicio', date) }
                                    />
                                    <label className="label-datepicker" htmlFor="inicio">Inicio</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <DatePicker
                                        id="fin"
                                        name="fin"
                                        className="form-control text-center input-datepícker"
                                        timeInputLabel="Time:"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        showTimeInput
                                        minDate={eventoData.inicio ? new Date(eventoData.inicio) : new Date()}
                                        selected={ eventoData.fin != null ? new Date(eventoData.fin) : '' }
                                        onChange={ date => handleDatePicker('fin', date) }
                                    />
                                    <label className="label-datepicker" htmlFor="fin">Finalización</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <div className="input-group-preppend">
                                    <input type="checkbox" className="btn-check" id="limite_check" autoComplete="off" onChange={e => handleCheckbox(e, 'tiene_limite')} checked={eventoData.tiene_limite ? true : false}/>
                                    <label className="btn btn-outline-primary h-100" id="label_limite_check" htmlFor="limite_check">{eventoData.tiene_limite ? 'Con limite' : 'Sin limite'}</label>
                                </div>
                                <div className="form-floating">
                                    <input type="number" className="form-control" placeholder="Capacidad" id="limite" name="limite" value={eventoData.limite} onChange={e => handleInputChange(e)} disabled={!eventoData.tiene_limite}/>
                                    <label htmlFor="capacidad">Capacidad</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 form-group mb-3">
                            <div className="input-group h-100">
                                <div className="input-group-preppend w-100">
                                    <input type="checkbox" className="btn-check w-100" id="soluciones_check" autoComplete="off" onChange={e => handleCheckbox(e, 'soluciones')} checked={eventoData.soluciones ? true : false}/>
                                    <label className="btn btn-outline-primary w-100 h-100" id="label_soluciones_check" htmlFor="soluciones_check">{eventoData.soluciones ? 'Tarjeta soluciones requerida' : 'Tarjeta soluciones sin requerir'}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Descripción" id="descripcion" name="descripcion" value={eventoData.descripcion} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="descripcion">Descripción</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarEvento}>Guardar</button>
                            <Link to='/eventos'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevoEvento;
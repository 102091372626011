import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Badge } from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber, sortTableDateReservacion, sortTableDateConfirmacion, sortTableStatusConfirmado } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { cleanProject, getProject, changeStatus } from '../../actions/projects';

const DetallesEvento = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Eventos',
            path: '/eventos'
        },
        {
            activo: true,
            nombre: 'Detalles del evento',
            path: '/eventos/detalles/'
        }
    ];

    const dispatch = useDispatch();

    const { id } = useParams();

    const { detalles } = useSelector(state => state.eventos);

    const [eventoData, setEventoData] = useState({
        indicadores_generales: {
            reservaciones: 0,
            confirmaciones: 0,
            entradas_restantes: 0,
            dias_restantes: 0
        },
        informacion_detallada: {
            _id: null,
            nombre: null,
            inicio: null,
            fin: null,
            limite: null,
            descripcion: null,
            status: 1,
            url_portada: process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
        },
        reservaciones: []
    });

    const [reservacionEscanear, setReservacionEscanear] = useState(null);

    const {indicadores_generales, informacion_detallada, reservaciones} = eventoData;

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const reservacionesColumnas = [
        {
            name: 'Joven',
            sortable: true, 
            selector: row => row.joven || 'Sin definir',
            grow: 2
        },
        {
            name: 'Código',
            sortable: true, 
            selector: row => row.codigo,
        },
        {
            name: 'Reservó',
            sortable: true, 
            sortFunction: sortTableDateReservacion, 
            selector: row => row.reservacion,
            cell: item => item.reservacion ? new Date(item.reservacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: 'Confirmó',
            sortable: true, 
            sortFunction: sortTableDateConfirmacion, 
            selector: row => row.confirmacion,
            cell: item => item.confirmacion ? new Date(item.confirmacion).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin definir'
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusConfirmado, 
            selector: row => {
                switch(row.confirmado){
                    case false:
                        return(
                            <h5><Badge pill bg="danger">Sin confirmar</Badge></h5>
                        )
                    case true:
                        return(
                            <h5><Badge pill bg="success">Confirmado</Badge></h5>
                        )
                    default:
                        break;
                }
            },
        }
    ];

    /* Escanear cupon */
    const handleEscanearEntrada = (entrada) => {
        setReservacionEscanear(entrada);    
    }

    /* Funciones generales */
    const generateStatus = (status) => {
        switch(status){
            case 0:
                return(
                    <Badge pill bg="success">Activo</Badge>
                )
            case 1:
                return(
                    <Badge pill bg="danger">Inactivo</Badge>
                )
            case 2:
                return(
                    <Badge pill bg="warning">Finalizado y activo</Badge>
                )
            case 3:
                return(
                    <Badge pill bg="danger">Finalizado e inactivo</Badge>
                )
            default:
                break;
        }
    }

    const handleCambioStatus = () => {
        if(informacion_detallada?.status == 0 || informacion_detallada?.status == 2){
            dispatch(changeStatus(id, informacion_detallada?.status == 0 ? 1 : 3, detalles));
        }else{
            dispatch(changeStatus(id, informacion_detallada?.status == 1 ? 0 : 2, detalles));
        }
    }

    useEffect(() => {
        dispatch(cleanProject());

        return () => {
            dispatch(cleanProject());
        }
    }, []);

    useEffect(() => {
        dispatch(getProject(id));

        if(id){
            rutasBread[1].path = rutasBread[1].path + id;

            dispatch(editBreadcrumb(rutasBread));
        }
    }, [id]);

    useEffect(() => {
        if(detalles){
            setEventoData({
                ...eventoData,
                indicadores_generales: detalles.indicadores_generales, 
                informacion_detallada: {
                    ...detalles.informacion_detallada,
                    imagen: detalles.informacion_detallada.url_portada || process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/imagen_placeholder.png'
                }, 
                reservaciones: detalles.reservaciones, 
            });
        }
    }, [detalles]); 

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                <style>{`
                        .contenedor_imagen_portada {
                            border-radius: 5%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            background: #fff;
                            border: 5px solid #111F62;
                            width: 100%;
                        }
                        
                        .contenedor_imagen{
                            position: relative;
                            width: 100%;
                            height: auto;
                            overflow: hidden;
                            border-radius: 5%;
                        }

                        #imagen_portada {
                            width: 100%;
                        }

                        .btn-badge-status{
                            background-color: #fff;
                            border: none;
                            outline: none;
                            padding: 0;
                        }

                        .valor_indicador {
                            font-size: 40px;
                            margin: 0;
                        }

                        .descripcion_indicador {
                            margin: 0;
                        }

                        .textareCampo {
                            height: 100px !important;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Detalles del evento</h3>

                    <div className="row mb-4">
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.reservaciones, 0, '', '') }</p>
                            <p className="descripcion_indicador">Asistencias reservadas</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.confirmaciones, 0, '', '') }</p>
                            <p className="descripcion_indicador">Asistencias confirmadas</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ indicadores_generales?.entradas_restantes != null ? formatNumber(indicadores_generales?.entradas_restantes, 0, '', '') : 'Sin límite' }</p>
                            <p className="descripcion_indicador">Entradas libres</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ formatNumber(indicadores_generales?.dias_restantes, 0, '', '') }</p>
                            <p className="descripcion_indicador">Dias restantes</p>
                        </div>
                        <div className="col-md text-center">
                            <p className="valor_indicador">{ informacion_detallada.soluciones ? 'Si' : 'No' }</p>
                            <p className="descripcion_indicador">Tarjeta soluciones requerida</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-3 py-3 text-center">
                            <label className="contenedor_imagen_portada">
                                <div className="contenedor_imagen">
                                    <img id="imagen_portada" src={ informacion_detallada?.imagen }/>
                                </div>
                            </label>
                            <div className="mt-2 d-flex justify-content-center">
                                <button className="btn-badge-status" onClick={() => handleCambioStatus()}>
                                    <h3 className="text-muted">
                                        { generateStatus(informacion_detallada?.status) }
                                    </h3>
                                </button>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="accordion" id="accordionDetalles">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Información del evento
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-3">
                                                <label className="fw-bold">Nombre</label>
                                                <p>{informacion_detallada?.nombre}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Inicio</label>
                                                <p>{informacion_detallada?.inicio ? new Date(informacion_detallada?.inicio).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(informacion_detallada?.inicio).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Finalización</label>
                                                <p>{informacion_detallada?.fin ? new Date(informacion_detallada?.fin).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) + ' a las ' + new Date(informacion_detallada?.fin).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : 'Sin definir'}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <label className="fw-bold">Capacidad</label>
                                                <p>{informacion_detallada?.limite ? formatNumber(informacion_detallada?.limite, 0, '', informacion_detallada?.limite != 1 ? ' asistentes' : ' asistente') : 'Sin limite'}</p>
                                            </div>

                                            <div className='col-md-12'>
                                                <label className='fw-bold'>Descripción</label>
                                                <p>{informacion_detallada?.descripcion}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            Reservaciones realizadas
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDetalles">
                                        <div className="accordion-body row">
                                            <div className="col-md-12 text-end mb-3">
                                                <button type="button" className="btn btn-primary me-2" onClick={() => handleEscanearEntrada({ id })}>Escanear reservación</button>
                                            </div>
                                            <DataTable
                                                columns={reservacionesColumnas}
                                                data={reservaciones}
                                                noDataComponent="No hay reservaciones registradas"
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 20, 50]}
                                                paginationComponentOptions={paginationOptions}
                                                highlightOnHover={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DetallesEvento;
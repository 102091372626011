import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber } from '../../utils/generalFunctions';

import { buscarDashboard } from '../../actions/dashboard';

const DashboardAdministrador = () => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.dashboard);
    const { user } = useSelector(state => state.auth);

    const [dashboardData, setDashboardData] = useState({
        jovenes: null, 
        administradores: null, 
        empresas: null, 
        cabina: null, 
        auditorio: null, 
        gimnasio: null, 
        psicologos: null, 
        nutriologos: null, 
        deportivos: null, 
        eventos_entradas: null, 
        eventos_confirmaciones: null, 
        programas_solicitantes: null, 
        programas_beneficiarios: null, 
        apoyos_solicitantes: null, 
        apoyos_beneficiarios: null, 
        becas_solicitantes: null, 
        becas_beneficiarios: null, 
        cabina_reservaciones: null, 
        auditorio_reservaciones: null, 
        gimnasio_inscritos: null, 
        gimnasio_activos: null, 
        gimnasio_adeudos: null, 
        promociones_realizadas: null, 
        promociones_cupones: null, 
        promociones_canjeos: null
    });

    useEffect(() => {
        dispatch(buscarDashboard('administrador'));
    }, []);

    useEffect(() => {
        if(detalles){
            setDashboardData({
                ...dashboardData, 
                jovenes: detalles.jovenes, 
                administradores: detalles.administradores, 
                empresas: detalles.empresas, 
                cabina: detalles.cabina, 
                auditorio: detalles.auditorio, 
                gimnasio: detalles.gimnasio, 
                psicologos: detalles.psicologos, 
                nutriologos: detalles.nutriologos, 
                deportivos: detalles.deportivos, 
                eventos_entradas: detalles.eventos_entradas, 
                eventos_confirmaciones: detalles.eventos_confirmaciones, 
                programas_solicitantes: detalles.programas_solicitantes, 
                programas_beneficiarios: detalles.programas_beneficiarios, 
                apoyos_solicitantes: detalles.apoyos_solicitantes, 
                apoyos_beneficiarios: detalles.apoyos_beneficiarios, 
                becas_solicitantes: detalles.becas_solicitantes, 
                becas_beneficiarios: detalles.becas_beneficiarios, 
                cabina_reservaciones: detalles.cabina_reservaciones, 
                auditorio_reservaciones: detalles.auditorio_reservaciones, 
                gimnasio_inscritos: detalles.gimnasio_inscritos, 
                gimnasio_activos: detalles.gimnasio_activos, 
                gimnasio_adeudos: detalles.gimnasio_adeudos, 
                promociones_realizadas: detalles.promociones_realizadas, 
                promociones_cupones: detalles.promociones_cupones, 
                promociones_canjeos: detalles.promociones_canjeos
            });
        }        
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .imagen_perfil {
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                            border: 2px solid #111f62;
                            margin-right: 15px;
                        }

                        .titulo {
                            color: #111f62;
                        }

                        .indicador {
                            font-size: 18px;
                            margin: 0;
                        }

                        .subtitulo {
                            margin: 0;
                            font-weight: 700;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
        
            <div className="col-md-12 d-flex align-items-center mb-2">
                <img className="imagen_perfil" src={user?.url_perfil_g != null ? user?.url_perfil_g : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'}/>
                <h4 className="titulo">
                    Bienvenido {[user?.nombre, user?.apellido_paterno, user?.apellido_materno].join(' ')}
                </h4>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Usuarios</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.jovenes != null && dashboardData.jovenes != undefined ? formatNumber(dashboardData.jovenes, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">jovenes</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.administradores != null && dashboardData.administradores != undefined ? formatNumber(dashboardData.administradores, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">administradores</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.empresas != null && dashboardData.empresas != undefined ? formatNumber(dashboardData.empresas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">empresas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Asistentes</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.cabina != null && dashboardData.cabina != undefined ? formatNumber(dashboardData.cabina, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">de cabina</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.auditorio != null && dashboardData.auditorio != undefined ? formatNumber(dashboardData.auditorio, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">de auditorio</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.gimnasio != null && dashboardData.gimnasio != undefined ? formatNumber(dashboardData.gimnasio, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">de gimnasio</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Asesores</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.psicologos != null && dashboardData.psicologos != undefined ? formatNumber(dashboardData.psicologos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">psicologicos</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.nutriologos != null && dashboardData.nutriologos != undefined ? formatNumber(dashboardData.nutriologos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">nutricionales</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.deportivos != null && dashboardData.deportivos != undefined ? formatNumber(dashboardData.deportivos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">deportivos</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Eventos</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.eventos_entradas != null && dashboardData.eventos_entradas != undefined ? formatNumber(dashboardData.eventos_entradas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">entradas</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.eventos_confirmaciones != null && dashboardData.eventos_confirmaciones != undefined ? formatNumber(dashboardData.eventos_confirmaciones, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">confirmaciones</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Programas</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.programas_solicitantes != null && dashboardData.programas_solicitantes != undefined ? formatNumber(dashboardData.programas_solicitantes, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">solicitantes</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.programas_beneficiarios != null && dashboardData.programas_beneficiarios != undefined ? formatNumber(dashboardData.programas_beneficiarios, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">beneficiarios</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Apoyos</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.apoyos_solicitantes != null && dashboardData.apoyos_solicitantes != undefined ? formatNumber(dashboardData.apoyos_solicitantes, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">solicitantes</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.apoyos_beneficiarios != null && dashboardData.apoyos_beneficiarios != undefined ? formatNumber(dashboardData.apoyos_beneficiarios, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">beneficiarios</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Becas</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.becas_solicitantes != null && dashboardData.becas_solicitantes != undefined ? formatNumber(dashboardData.becas_solicitantes, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">solicitantes</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.becas_beneficiarios != null && dashboardData.becas_beneficiarios != undefined ? formatNumber(dashboardData.becas_beneficiarios, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">beneficiarios</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Cabina y auditorio</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.cabina_reservaciones != null && dashboardData.cabina_reservaciones != undefined ? formatNumber(dashboardData.cabina_reservaciones, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">reservaciones</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.auditorio_reservaciones != null && dashboardData.auditorio_reservaciones != undefined ? formatNumber(dashboardData.auditorio_reservaciones, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">reservaciones</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Gimnasio</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.gimnasio_inscritos != null && dashboardData.gimnasio_inscritos != undefined ? formatNumber(dashboardData.gimnasio_inscritos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">inscritos</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.gimnasio_activos != null && dashboardData.gimnasio_activos != undefined ? formatNumber(dashboardData.gimnasio_activos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">activos</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.gimnasio_adeudos != null && dashboardData.gimnasio_adeudos != undefined ? formatNumber(dashboardData.gimnasio_adeudos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">adeudando</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Promociones</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.promociones_realizadas != null && dashboardData.promociones_realizadas != undefined ? formatNumber(dashboardData.promociones_realizadas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">realizadas</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.promociones_cupones != null && dashboardData.promociones_cupones != undefined ? formatNumber(dashboardData.promociones_cupones, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">cupones</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.promociones_canjeos != null && dashboardData.promociones_canjeos != undefined ? formatNumber(dashboardData.promociones_canjeos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">canjeos</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardAdministrador;
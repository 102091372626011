import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { addUser } from '../../actions/users';

const NuevoUsuarioAdministrador = () => {

    let rutasBread = [
        {
            activo: false,
            nombre: 'Usuarios',
            path: '/usuarios'
        },
        {
            activo: true,
            nombre: 'Nuevo usuario',
            path: '/usuarios/nuevo'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { usuario } = useSelector(state => state.auth);

    const [usuarioData, setUsuarioData] = useState({
        id_empresa: null,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        tipo: null,
        email: '',
        password: '',
        passwordConf: ''
    });
    
    const [tipoUsuarioData, setTipoUsuarioData] = useState([]);
    const tiposUsuariosFilter = [
        { value: 0, label: 'Administrador' }, 
        { value: 1, label: 'Asistente de cabina' }, 
        { value: 2, label: 'Asistente de auditorio' }, 
        { value: 3, label: 'Asistente de gimnasio' }, 
        { value: 4, label: 'Asesor psicológico' }, 
        { value: 5, label: 'Asesor nutricional' }, 
        { value: 6, label: 'Asesor deportivo' }
    ];

    /* Nuevo usuario */
    const handleInputChange = ({ target }) => {
        setUsuarioData({
            ...usuarioData,
            [target.name]: target.value
        });
    }

    const handleSelectTipo = (option) => {
        setTipoUsuarioData(option);

        setUsuarioData({
            ...usuarioData,
            tipo: option != null ? option.value : null
        });
    }

    const handleGuardarUsuario = async () => {
        if(usuarioData.nombre && usuarioData.apellido_paterno && usuarioData.email && usuarioData.password && usuarioData.passwordConf){
            if(usuarioData.password !== usuarioData.passwordConf){
                await dispatch(setAlert('Las contraseñas no coinciden', 'danger'));
            }else{
                await dispatch(addUser(usuarioData));

                await navigate("/usuarios");
            }
        }else{
            await dispatch(setAlert('Todos los campos son obligatorios', 'danger'));
        }
    }

    /* Funciones generales */
    const onViewPassword = (origen) => {
        if(usuarioData.password !== ''){
            let loginInputType = document.getElementById(origen).type;
            
            if(loginInputType === 'password')document.getElementById(origen).type = 'text';
            else document.getElementById(origen).type = 'password';
        }
    }

    useEffect(() => {
        setUsuarioData({
            ...usuarioData,
            id_empresa: usuario?.empresa || null, 
            tipo: usuario?.empresa ? 7 : null,
        });
    }, [usuario]);

    useEffect(() => {
        dispatch(editBreadcrumb(rutasBread));
    }, [rutasBread]);  

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-10 offset-md-1 pb-4">
                    <h3 className="mb-3 mt-5">Nuevo usuario</h3>
                
                    <div className="row">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={usuarioData.nombre} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Apellido paterno" id="apellido_paterno" name="apellido_paterno" value={usuarioData.apellido_paterno} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="apellido_paterno">Apellido paterno</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Apellido materno" id="apellido_materno" name="apellido_materno" value={usuarioData.apellido_materno} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="apellido_materno">Apellido materno</label>
                                </div>
                            </div>
                        </div>

                        {
                            usuario?.empresa ?
                                null
                            :
                                <div className="col-md-3 form-group mb-3">
                                    <div className="input-group h-100">
                                        <Select
                                            id="tipo"
                                            name="tipo"
                                            className="select-group"
                                            classNamePrefix="react-select"
                                            placeholder="Tipo"
                                            options={tiposUsuariosFilter}
                                            value={tipoUsuarioData}
                                            onChange={(value) => handleSelectTipo(value)}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        />
                                    </div>
                                </div>
                        }

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Correo" id="email" name="email" value={usuarioData.email} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="email">Correo</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="password" className="form-control" placeholder="Contraseña" id="password" name="password" value={usuarioData.password} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="password">Contraseña</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('password')}><i className="fa-solid fa-eye"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="password" className="form-control" placeholder="Confirmar Contraseña" id="passwordConf" name="passwordConf" value={usuarioData.passwordConf} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="passwordConf">Confirmar contraseña</label>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('passwordConf')}><i className="fa-solid fa-eye"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleGuardarUsuario}>Guardar</button>
                            <Link to='/usuarios'><button className="btn btn-danger float-end me-2">Cancelar</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default NuevoUsuarioAdministrador;
import { USER } from '../actions/types';

const initialState = {
    list: [],
    details: null, 
    filter_window: false, 
    filters: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case USER.FIND:
            return {
                ...state,
                list: payload
            };

        case USER.ADD:
            let usuarios_agregar = state.list;

            usuarios_agregar = [
                ...usuarios_agregar, payload
            ];

            return {
                ...state, 
                list: usuarios_agregar
            };

        case USER.DETAILS:
            return {
                ...state,
                details: payload
            };

        case USER.EDIT:
            let usuarios_editar = state.list;

            usuarios_editar = usuarios_editar.map(usuario => usuario._id === payload._id ? payload : usuario);

            return {
                ...state, 
                list: usuarios_editar
            };

        case USER.DELETE:
            return {
                ...state,
                list: state.list.filter( usuario => usuario._id !== payload.id )
            };

        case USER.FILTERS:
            return {
                ...state,
                filter_window: payload
            };

        default:
            return state;
    }
}
import { combineReducers } from 'redux';

import alert from './alert';
import loader from './loader';
import breadcrumb from './breadcrumb';

import auth from './auth';
import dashboard from './dashboard';
import users from './users';
import projects from './projects';
import configuration from './configuration';

export default combineReducers({
    alert, loader, breadcrumb, auth, dashboard, projects, users, configuration
});
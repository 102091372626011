import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';

const Sidebar = () => {

    const dispatch = useDispatch();

    const { permissions } = useSelector(state => state.auth.user);

    return (
        <Fragment>
            <aside id="sidebar" className="sidebar">
				<ul className="sidebar-nav" id="sidebar-nav">
					<li className="nav-item">
						<Link className="nav-link" to="/home">
							<i className="bi bi-grid"></i>
							<span>Home</span>
						</Link>
					</li>

					<li className="nav-item">
						<a className="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
							<i className="bi bi-house-door"></i><span>Projects</span><i className="bi bi-chevron-down ms-auto"></i>
						</a>
						<ul id="forms-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
							<li>
								<Link to="/projects/add">
									<i className="bi bi-circle"></i><span>Create</span>
								</Link>
							</li>
							<li>
								<Link to="/projects">
									<i className="bi bi-circle"></i><span>List</span>
								</Link>
							</li>
						</ul>
					</li>

					<li class="nav-item">
						<Link class="nav-link collapsed" to="/employees">
							<i class="bi bi-people"></i>
							<span>Employees</span>
						</Link>
					</li>

					<li class="nav-item">
						<Link class="nav-link collapsed" to="/payroll">
							<i class="bi bi-currency-dollar"></i>
							<span>Payroll</span>
						</Link>
					</li>
				</ul>
			</aside>
        </Fragment>
    )
}

export default Sidebar;
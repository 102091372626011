import axios from 'axios';

import { USER } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const findUsers = (filters) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filters);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/list`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USER.FIND,
            payload: res.data.users
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cleanUsers= () => dispatch => {
    dispatch({
        type: USER.FIND,
        payload: []
    });
}

export const findUsersType = (types) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        types
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/list_type`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USER.FIND,
            payload: res.data.users
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const addUser = (user) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(user);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/add`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USER.ADD,
            payload: res.data.user
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const getUser = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/details`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USER.DETAILS,
            payload: res.data.user
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const cleanUser = () => dispatch => {
    dispatch({
        type: USER.DETAILS,
        payload: null
    });
}

export const editUser = (user) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(user);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/edit`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USER.EDIT,
            payload: res.data.user
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const deleteUser = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/users/delete`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USER.DELETE,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filterUsers = (option) => dispatch => {
    dispatch({
        type: USER.FILTERS,
        payload: option
    });
}

// Alerts
export const ALERT = {
    SET: 'SET_ALERT', 
    REMOVE: 'REMOVE_ALERT'
};

// Breadcrumb
export const BREADCRUMB = {
    EDIT: 'EDIT_BREADCRUMB'
};

// Loader
export const LOADER = {
    SHOW: 'SHOW_LOADER',
    HIDE: 'HIDE_LOADER'

};

// Auth
export const AUTH = {
    LOGIN: 'LOGIN',
    LOGIN_ERROR: 'LOGIN_ERROR',
    GET_USER: 'GET_USER',
    GET_USER_ERROR: 'GET_USER_ERROR',
    LOGOUT: 'LOGOUT'
};

// Dashboard
export const DASHBOARD = {
    GET: 'GET_DASHBOARD'
};

// Users
export const USER = {
    FIND: 'FIND_USERS', 
    ADD: 'ADD_USER', 
    DETAILS: 'DETAILS_USER', 
    EDIT: 'EDIT_USER', 
    DELETE: 'DELETE_USER', 
    FILTERS: 'FILTERS_USERS'
};

// Projects
export const PROJECT = {
    FIND: 'FIND_PROJECTS', 
    ADD: 'ADD_PROJECT', 
    DETAILS: 'DETAILS_PROJECT', 
    EDIT: 'EDIT_PROJECT', 
    DELETE: 'DELETE_PROJECT', 
    FILTERS: 'FILTERS_PROJECTS'
};

// Configuration
export const CONFIGURATION_PROFILE = {
    EDIT: 'EDIT_PROFILE'
};

import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const Loader = () => {
    return (
        <div className="contenedor_loader">
            <div className="text-center">
                <img className="d-inline-block my-4" alt="logo" width="200" src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_tarjeta_joven_blanco.png'}/>
                <div className="contenedor_animacion">
                    <ThreeDots 
                        height="70" 
                        width="70" 
                        radius="9"
                        color="#7f81d3" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{ display: 'block' }}
                        visible={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Loader;
